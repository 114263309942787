import './App.css';
import Dictaphone from "./Dictaphone";
import {BrowserRouter as Router, Route} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <img src="/logo192.png"></img>
          <p style={{fontWeight: "bold"}}>Dinner Talk Translator</p>
        </div>
        <Dictaphone />
      </header>
      <footer>
        <hr />
        <p style={{color: "white", fontWeight: "bold"}}>Powered by AAPICO ITS </p>
      </footer>
    </div>
  );
}

const MainApp = () => {
  return <Router>
    <Route path='/' component={App} />
  </Router>
}

export default MainApp;
